import React, { useState } from 'react';
import styles from './ProgressSteps.module.css';
import { FaCheck, FaLock, FaMapMarkerAlt } from 'react-icons/fa';
import { ProgressStepsProps } from '../types';

const ProgressSteps: React.FC<ProgressStepsProps> = ({ steps }) => {
    const getDefaultSelectedIndex = () => {
        const hereIndex = steps.findIndex(step => step.status === 'here');
        if (hereIndex !== -1) return hereIndex;

        const lastCompletedIndex = steps.map(step => step.status === 'completed').lastIndexOf(true);
        if (lastCompletedIndex !== -1) return lastCompletedIndex;

        return 0;
    };

    const [selectedStep, setSelectedStep] = useState(getDefaultSelectedIndex());

    const getStepIcon = (status: string) => {
        switch (status) {
            case 'completed':
                return <FaCheck className={styles.icon} />;
            case 'here':
                return <FaMapMarkerAlt className={styles.icon} />;
            default:
                return <FaLock className={styles.icon} />;
        }
    };

    const formatDescription = (description: string) => {
        return description.split('\n').map((line, i) => (
            <React.Fragment key={i}>
                {line}
                {i < description.split('\n').length - 1 && <br />}
            </React.Fragment>
        ));
    };

    return (
        <div className={styles.container}>
            <div className={styles.tabsContainer}>
                {steps.map((step, index) => (
                    <button
                        key={index}
                        className={`${styles.tab} ${selectedStep === index ? styles.selectedTab : ''} ${styles[step.status]}`}
                        onClick={() => setSelectedStep(index)}
                    >
                        {getStepIcon(step.status)}
                        <span>{step.category}</span>
                    </button>
                ))}
            </div>

            <div className={styles.stepContent}>
                <h3 className={styles.stepTitle}>{steps[selectedStep].title}</h3>
                <p className={styles.stepDescription}>
                    {formatDescription(steps[selectedStep].description)}
                </p>
                <span className={`${styles.statusTag} ${styles[steps[selectedStep].status]}`}>
                    {steps[selectedStep].statusText}
                </span>
            </div>
        </div>
    );
};

export default ProgressSteps; 