import React, { useState } from 'react';
import styles from './RehabProgress.module.css';
import { FiInfo, FiChevronDown } from 'react-icons/fi';
import { Patient } from '../types';

interface CompletionData {
    labels: string[];
    values: number[];
}

interface RehabProgressProps {
    yesterdayProgress: CompletionData;
    weekProgress: CompletionData;
    totalProgress: CompletionData;
    patient: Patient;
}

const RehabProgress: React.FC<RehabProgressProps> = ({
    yesterdayProgress,
    weekProgress,
    totalProgress,
    patient
}) => {
    const firstName = patient.name.split(' ')[0];

    const [visibleRings, setVisibleRings] = useState<Record<string, boolean>>({
        yesterday: false,
        week: false,
        total: true
    });

    const [isInfoExpanded, setIsInfoExpanded] = useState(false);

    const toggleRing = (key: string) => {
        setVisibleRings(prev => ({
            ...prev,
            [key]: !prev[key]
        }));
    };

    const calculatePercentage = (completed: number, total: number) => {
        if (total === 0) return 0;
        const percentage = Math.round((completed / total) * 100);
        return percentage === 0 ? 1 : percentage;
    };

    const getColorForPercentage = (percentage: number) => {
        if (percentage < 20) {
            return {
                ring: 'rgb(200, 40, 40)',      // Red
                background: 'rgba(255, 68, 68, 0.15)'
            };
        } else if (percentage < 80) {
            return {
                ring: 'rgb(255, 100, 0)',      // Orange
                background: 'rgba(255, 170, 85, 0.15)'
            };
        } else {
            return {
                ring: 'rgb(60, 140, 60)',      // Green
                background: 'rgba(92, 184, 92, 0.15)'
            };
        }
    };

    const createCircle = (radius: number, data: CompletionData) => {
        const progress = calculateProgress(data);
        const percentage = calculatePercentage(progress.completed, progress.total);
        const circumference = 2 * Math.PI * radius;
        const dashOffset = circumference - (percentage / 100) * circumference;
        const colors = getColorForPercentage(percentage);

        return (
            <>
                <circle
                    cx="140"
                    cy="140"
                    r={radius}
                    fill="none"
                    stroke={colors.background}
                    strokeWidth="12"
                    strokeLinecap="round"
                />
                <circle
                    cx="140"
                    cy="140"
                    r={radius}
                    fill="none"
                    stroke={colors.ring}
                    strokeWidth="12"
                    strokeLinecap="round"
                    strokeDasharray={circumference}
                    strokeDashoffset={dashOffset}
                />
            </>
        );
    };

    const getButtonStyle = (key: string, progress: { completed: number; total: number }) => {
        const percentage = calculatePercentage(progress.completed, progress.total);
        const colors = getColorForPercentage(percentage);
        const isActive = visibleRings[key];

        return {
            color: isActive ? colors.ring : '#6b7280',
            backgroundColor: isActive ? colors.background : 'white',
            boxShadow: isActive ? 'none' : '0 2px 4px rgba(0, 0, 0, 0.1)'
        };
    };

    const calculateProgress = (data: CompletionData) => {
        const completed = data.values[0]; // "completed" count
        const incomplete = data.values[1]; // "incomplete" count
        const missed = data.values[2]; // "missed" count
        const total = completed + incomplete + missed;

        return {
            completed: completed + incomplete, // Both completed and incomplete count as "attempted"
            total: total
        };
    };

    const calculateStats = (data: CompletionData) => {
        const completed = data.values[0];          // completed
        const incomplete = data.values[1];         // incomplete
        const missed = data.values[2];            // missed
        const attempted = completed + incomplete;  // attempted = completed + incomplete
        const assigned = attempted + missed;       // assigned = attempted + missed

        return {
            completed,
            attempted,
            assigned
        };
    };

    const getVisibleTimeframe = () => {
        if (visibleRings.total) return "since injury";
        if (visibleRings.week) return "in last 7 days";
        if (visibleRings.yesterday) return "yesterday";
        return "";
    };

    const getVisiblePercentage = () => {
        if (visibleRings.total) {
            const progress = calculateProgress(totalProgress);
            return calculatePercentage(progress.completed, progress.total);
        }
        if (visibleRings.week) {
            const progress = calculateProgress(weekProgress);
            return calculatePercentage(progress.completed, progress.total);
        }
        if (visibleRings.yesterday) {
            const progress = calculateProgress(yesterdayProgress);
            return calculatePercentage(progress.completed, progress.total);
        }
        return null;
    };

    return (
        <div className={styles.container}>
            <div className={styles.circleContainer}>
                <svg width="280" height="280" viewBox="0 0 280 280">
                    {/* Since injury - innermost circle */}
                    {visibleRings.total && createCircle(90, totalProgress)}

                    {/* Last 7 days - middle circle */}
                    {visibleRings.week && createCircle(105, weekProgress)}

                    {/* Yesterday - outermost circle */}
                    {visibleRings.yesterday && createCircle(120, yesterdayProgress)}
                </svg>
                <div className={styles.circleContent}>
                    {getVisiblePercentage() !== null && (
                        <>
                            <div className={styles.percentageWrapper}>
                                <span className={styles.percentageNumber}>{getVisiblePercentage()}</span>
                                <span className={styles.percentageSymbol}>%</span>
                            </div>
                            <span className={styles.label}>
                                exercises attempted {getVisibleTimeframe()}
                            </span>
                        </>
                    )}
                </div>
            </div>

            <div className={styles.progressBars}>
                <div className={styles.progressItem}>
                    <button
                        className={`${styles.timeframeButton} ${visibleRings.yesterday ? styles.active : ''}`}
                        onClick={() => toggleRing('yesterday')}
                        style={getButtonStyle('yesterday', calculateProgress(yesterdayProgress))}
                    >
                        yesterday
                    </button>
                    {visibleRings.yesterday && (
                        <div className={styles.stats}>
                            <span>{yesterdayProgress.values[0]} / {calculateStats(yesterdayProgress).assigned} completed</span>
                            <span>{calculateStats(yesterdayProgress).attempted} / {calculateStats(yesterdayProgress).assigned} attempted</span>
                        </div>
                    )}
                </div>

                <div className={styles.progressItem}>
                    <button
                        className={`${styles.timeframeButton} ${visibleRings.week ? styles.active : ''}`}
                        onClick={() => toggleRing('week')}
                        style={getButtonStyle('week', calculateProgress(weekProgress))}
                    >
                        last 7 days
                    </button>
                    {visibleRings.week && (
                        <div className={styles.stats}>
                            <span>{weekProgress.values[0]} / {calculateStats(weekProgress).assigned} completed</span>
                            <span>{calculateStats(weekProgress).attempted} / {calculateStats(weekProgress).assigned} attempted</span>
                        </div>
                    )}
                </div>

                <div className={styles.progressItem}>
                    <button
                        className={`${styles.timeframeButton} ${visibleRings.total ? styles.active : ''}`}
                        onClick={() => toggleRing('total')}
                        style={getButtonStyle('total', calculateProgress(totalProgress))}
                    >
                        since injury
                    </button>
                    {visibleRings.total && (
                        <div className={styles.stats}>
                            <span>{totalProgress.values[0]} / {calculateStats(totalProgress).assigned} completed</span>
                            <span>{calculateStats(totalProgress).attempted} / {calculateStats(totalProgress).assigned} attempted</span>
                        </div>
                    )}
                </div>
            </div>

            <div className={styles.infoCard} onClick={() => setIsInfoExpanded(!isInfoExpanded)}>
                <div className={styles.infoHeader}>
                    <FiInfo size={24} className={styles.infoIcon} />
                    <h3 className={styles.infoTitle}>
                        Can exercises really impact one's recovery timeline?
                    </h3>
                    <FiChevronDown 
                        size={24} 
                        className={`${styles.chevronIcon} ${isInfoExpanded ? styles.expanded : ''}`}
                    />
                </div>
                {isInfoExpanded && (
                    <div className={styles.infoContent}>
                        Rehabilitation exercises speed up the return to play by a whole week on average and cut the risk of long-term symptoms by 50% 
                        (<a href="https://pubmed.ncbi.nlm.nih.gov/34600629/" target="_blank" rel="noopener noreferrer">Leddy, 2021</a>).
                        <br />
                        <br />
                        You can make the difference—motivate {firstName} to complete their exercises in order to get back to what they love stronger and sooner.
                    </div>
                )}
            </div>
        </div>
    );
};

export default RehabProgress;
