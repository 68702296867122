import axios from 'axios';

const GET_PATIENT_DATA_URL = process.env.REACT_APP_GET_PATIENT_DATA_URL || 'https://ge8le67c0k.execute-api.us-east-2.amazonaws.com/production/getUserData';
const SET_EXERCISE_STATUS_URL = process.env.REACT_APP_SET_EXERCISE_STATUS_URL || 'https://ge8le67c0k.execute-api.us-east-2.amazonaws.com/production/postExerciseStatus';
const RESET_EXERCISE_STATUS_URL = process.env.REACT_APP_RESET_EXERCISE_STATUS_URL || 'https://ge8le67c0k.execute-api.us-east-2.amazonaws.com/production/deleteExerciseStatus';
const GET_RESULTS_DATA_URL = process.env.REACT_APP_GET_RESULTS_DATA_URL || 'https://fmqsannd3yfc7xiezzicfobxg40cixjb.lambda-url.us-east-2.on.aws';
const GET_PATIENT_DATA_API_VALUE = process.env.REACT_APP_GET_PATIENT_DATA_API_VALUE || 'rz0YqXT8pW2m8oxPt3rhxa1MWbqFiV1b46zl1o40';

const apiClient = axios.create({
  headers: {
    'Content-Type': 'application/json',
    // 'x-api-key': GET_PATIENT_DATA_API_VALUE
  },
  withCredentials: false,
});

export const getPatientData = async () => {
  try {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const urlParams = new URLSearchParams(window.location.search);
    const uuid = urlParams.get('uuid') || "null";
    const response = await apiClient.get(`${GET_PATIENT_DATA_URL}?uuid=${uuid}&timezone=${userTimezone}`);
    return response.data.body;
  } catch (error) {
    console.error('Error fetching patient data:', error);
    throw error;
  }
};

export const setCompletionStatus = async (
  action: string,
  content: Record<string, any>,
  patientId: string,
  added_rehab_id: string
) => {
  try {
    const requestData = {
      action,
      content,
      patient_id: patientId,
      added_rehab_id: added_rehab_id,
    };
    console.log('Full request data being sent:', requestData);
    console.log('Request URL:', SET_EXERCISE_STATUS_URL);
    
    const response = await apiClient.post(SET_EXERCISE_STATUS_URL, requestData);
    console.log('Response received:', response);
    
    return response.data.body.patient_action_id;
  } catch (error) {
    console.error('Error setting completion status:', error);
    if (axios.isAxiosError(error)) {
      console.error('Response data:', error.response?.data);
      console.error('Response status:', error.response?.status);
    }
    throw error;
  }
};

export const resetExerciseStatus = async (
  patient_action_id: number
) => {
  try {
    console.log('Resetting exercise status with patient_action_id:', patient_action_id);
    const response = await apiClient.delete(`${RESET_EXERCISE_STATUS_URL}?patient_action_id=${patient_action_id}`);
    return response.data;
  } catch (error) {
    console.error('Error resetting exercise status:', error);
    throw error;
  }
};

export const getResultsData = async (
  uuid: string,
  added_rehab_id: string
) => {
  try {
    console.log('Getting results data for:', {
      uuid: uuid,
      rehab_id: added_rehab_id
    });
    const response = await apiClient.get(`${GET_RESULTS_DATA_URL}?uuid=${uuid}&rehab_id=${added_rehab_id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching results data:', error);
    throw error;
  }
};
