import React, { useMemo } from 'react';
import styles from './LearningAccommodations.module.css';
import { LearningAccommodation } from '../types';

interface LearningAccommodationsProps {
    accommodations: LearningAccommodation[];
}

const LearningAccommodations: React.FC<LearningAccommodationsProps> = ({ accommodations }) => {
    // Randomize accommodations order using Fisher-Yates shuffle
    const randomizedAccommodations = useMemo(() => {
        return [...accommodations]
            .map(value => ({ value, sort: Math.random() }))
            .sort((a, b) => a.sort - b.sort)
            .map(({ value }) => value);
    }, [accommodations]);

    const formatDescription = (description: string) => {
        return description.split('\n').map((line, i) => (
            <React.Fragment key={i}>
                {line}
                {i < description.split('\n').length - 1 && <br />}
            </React.Fragment>
        ));
    };

    return (
        <div className={styles.container}>
            <div className={styles.titleContainer}>
                <h3 className={styles.title}>Accommodations</h3>
                <span className={styles.count}>({accommodations.length})</span>
            </div>
            <div className={styles.scrollContainer}>
                {randomizedAccommodations.map((accommodation, index) => (
                    <div key={index} className={styles.card}>
                        <h4 className={styles.cardTitle}>{accommodation.title}</h4>
                        <p className={styles.cardDescription}>
                            {formatDescription(accommodation.description)}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LearningAccommodations; 