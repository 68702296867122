import React, { useState } from 'react';
import { Exercise, NonRehabExercisesProps } from '../types';
import styles from './NonRehabExercises.module.css';
import TagInfoWindow from './TagInfoWindow';

const NonRehabExercises: React.FC<NonRehabExercisesProps> = ({ 
  exercises, 
  fullName, 
  role = 'patient',
  patientName,
  teamMemberName
}) => {
  const [selectedExercise, setSelectedExercise] = useState<Exercise | null>(null);

  const handleTagClick = (exercise: Exercise) => {
    setSelectedExercise(exercise);
  };

  const handleCloseTagInfo = () => {
    setSelectedExercise(null);
  };

  if (!exercises || exercises.length === 0) {
    return null;
  }

  const renderTitle = () => {
    console.log('NonRehabExercises props:', {
      role,
      patientName,
      teamMemberName
    });

    if (patientName && teamMemberName) {
      const patientFirstName = patientName.split(' ')[0];
      const teamMemberFirstName = teamMemberName.split(' ')[0];
      return `${teamMemberFirstName}, here's ${patientFirstName}'s gameplan:`;
    }
    const firstName = fullName.split(' ')[0];
    return `${firstName}, here's our gameplan:`;
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.sectionTitle}>
        {renderTitle()}
      </h2>
      <div className={styles.tagContainer}>
        {exercises.map((exercise) => (
          <span
            key={exercise.id || `non-rehab-${exercise.title}`}
            className={styles.tag}
            onClick={() => handleTagClick(exercise)}
          >
            {exercise.title}
          </span>
        ))}
      </div>
      {selectedExercise && (
        <TagInfoWindow
          title={selectedExercise.title}
          description={selectedExercise.description}
          onClose={handleCloseTagInfo}
        />
      )}
    </div>
  );
};

export default NonRehabExercises;
