interface ExerciseContent {
  comments?: string;
  video?: string;
  survey_question_list?: any[];
}

export interface Exercise {
  id: string;
  title: string;
  description: string;
  action: 'assigned' | 'completed' | 'incomplete';
  patientId: string;
  added_rehab_id: string;
  type: string;
  content?: ExerciseContent;
  why_simple?: string | null;
}

export interface PatientRehab {
  status: string;
}

interface PlayData {
  play_current_stage: number;
  when_will_be_fully_back_tag: string;
  when_will_be_fully_back_tag_color: string;
  play_categories: string[];
  play_titles: string[];
  play_descriptions: string[];
  play_status_texts: string[];
  play_statuses: string[];
  play_sub_state: string[];
  permissions_play: string[];
}

export interface LearningAccommodation {
  title: string;
  description: string;
}

interface LearnData {
  how_best_to_support_tag: string;
  how_best_to_support_tag_color: string;
  learn_current_stage: number;
  learn_categories: string[];
  learn_titles: string[];
  learn_descriptions: string[];
  learn_status_texts: string[];
  learn_statuses: string[];
  learn_sub_state: string[];
  learning_accommodations: LearningAccommodation[];
  permissions_learn: string[];
}

interface CompletionData {
  labels: string[];
  values: number[];
}

interface RehabData {
  recovery_effort: string;
  recovery_effort_color: string;
  yesterday_completion: CompletionData;
  past7days_completion: CompletionData;
  since_injury_completion: CompletionData;
  permissions_rehab: string[];
}

// For coach/teacher view (the detailed patient data)
export interface Patient {
  patient_id: number;
  patient_uuid: string;
  name: string;
  subtitle: string;
  timestamp_at_injury: string;
  top_right_tag?: string;
  play?: PlayData;
  learn?: LearnData;
  rehab?: RehabData;
}

// Update App.tsx to use this logic for role checking
export const isPatientOrCaretaker = (role: string): boolean => {
  return role === 'patient' || role === 'caretaker';
};

export interface Step {
  category: string;
  title: string;
  description: string;
  status: 'completed' | 'here' | 'locked';
  statusText: string;
}

export interface ProgressStepsProps {
  steps: Step[];
}

export interface CaretakerMode {
  isCaretaker: boolean;
  caretakerName: string;
  patientName: string;
}

export interface NonRehabExercisesProps {
  exercises: Exercise[] | undefined;
  fullName: string;
  role?: string;
  patientName?: string;
  teamMemberName?: string;
  teamMemberRole?: string;
}

export interface OverviewProps {
  rehabExercises: Exercise[];
  nonRehabExercises: Exercise[];
  fullName: string;
  error: string | null;
  role: string;
  patientName?: string;
  teamMemberName?: string;
  teamMemberRole?: string;
  patientUuid?: string;
}

