// src/pages/PatientsOverview.tsx
import React, { useState } from 'react';
import styles from './PatientsOverview.module.css';
import { Patient } from '../types';
import { FaRocket, FaBan, FaHourglassHalf, FaChevronRight } from 'react-icons/fa';
import PatientDetails from './PatientDetails';

interface PatientsOverviewProps {
  patients: Patient[] | undefined;
  onPatientSelect: (patientId: number) => void;
  showingPatientDetails: boolean;
  setShowingPatientDetails: (show: boolean) => void;
  teamMemberName?: string;
}

interface StatusInfo {
  color: string;
  textColor: string;
  icon: JSX.Element | null;
}

const PatientsOverview: React.FC<PatientsOverviewProps> = ({
  patients,
  onPatientSelect,
  showingPatientDetails,
  setShowingPatientDetails,
  teamMemberName
}) => {
  const [selectedPatient, setSelectedPatient] = useState<Patient | null>(null);

  const getStatusInfo = (status: string): StatusInfo => {
    switch (status) {
      case 'red':
        return {
          color: 'rgba(255, 68, 68, 0.15)',  // Soft red background
          textColor: 'rgb(200, 40, 40)',     // Darker red text
          icon: <FaBan className={styles.statusIcon} />
        };
      case 'yellow':
        return {
          color: 'rgba(255, 170, 85, 0.15)',  // Soft orange background
          textColor: 'rgb(255, 100, 0)',     // Darker orange text
          icon: <FaHourglassHalf className={styles.statusIcon} />
        };
      case 'green':
        return {
          color: 'rgba(92, 184, 92, 0.15)',   // Soft green background
          textColor: 'rgb(60, 140, 60)',      // Darker green text
          icon: <FaRocket className={styles.statusIcon} />
        };
      default:
        return {
          color: '#888888',
          textColor: '#ffffff',
          icon: null
        };
    }
  };

  const handleCardClick = (patientId: number) => {
    const patient = patients?.find(p => p.patient_id === patientId);
    if (patient) {
      setSelectedPatient(patient);
      setShowingPatientDetails(true);
    }
  };

  const handleBack = () => {
    if (patients && patients.length > 1) {
      setSelectedPatient(null);
    } else {
      setShowingPatientDetails(false);
      onPatientSelect(-1);
    }
  };

  if (!patients) {
    return <div>Loading patients...</div>;
  }

  if (patients.length === 0) {
    return (
      <div className={styles.emptyStateContainer}>
        <h1 className={styles.emptyStateTitle}>
          Great news, {teamMemberName?.split(' ')[0]}! 🎉
        </h1>
        <p className={styles.emptyStateSubtitle}>
          Everyone's in the clear - no concussions to report.
        </p>
      </div>
    );
  }

  if ((selectedPatient || patients.length === 1) && showingPatientDetails) {
    return <PatientDetails
      patient={selectedPatient || patients[0]}
      onBack={handleBack}
      teamMemberName={teamMemberName}
    />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.cardsContainer}>
        {patients.map((patient) => {
          const statusInfo = getStatusInfo(patient.rehab?.recovery_effort_color || 'default');

          return (
            <div
              key={patient.patient_id}
              className={styles.card}
              onClick={() => handleCardClick(patient.patient_id)}
            >
              <div className={styles.headerGroup}>
                <div className={styles.nameGroup}>
                  <div className={styles.initialsCircle}>
                    {patient.name.split(' ').map(n => n[0]).join('')}
                  </div>
                  <h2 className={styles.patientName}>{patient.name}</h2>
                </div>
                {patient.top_right_tag && (
                  <div className={styles.topRightTag}>
                    {patient.top_right_tag}
                  </div>
                )}
              </div>
              <div
                className={styles.status}
                style={{
                  backgroundColor: statusInfo.color,
                  color: statusInfo.textColor
                }}
              >
                {statusInfo.icon}
                <span>{patient.rehab?.recovery_effort || 'Status Unknown'}</span>
              </div>
              <FaChevronRight className={styles.nextPageArrow} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PatientsOverview;