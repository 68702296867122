import React from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import styles from './ActionButtons.module.css';

export interface ActionButton {
    icon: React.ReactNode;
    label: string;
    href?: string;
    onClick?: () => void;
}

interface ActionButtonsProps {
    buttons: ActionButton[];
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ buttons }) => {
    return (
        <div className={styles.scrollContainer}>
            <div className={styles.buttonContainer}>
                {buttons.map((button, index) => (
                    button.href ? (
                        <a
                            key={index}
                            href={button.href}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={styles.actionButton}
                        >
                            {button.icon}
                            {button.label}
                        </a>
                    ) : (
                        <button
                            key={index}
                            onClick={button.onClick}
                            className={styles.actionButton}
                        >
                            {button.icon}
                            {button.label}
                        </button>
                    )
                ))}
            </div>
        </div>
    );
};

export default ActionButtons; 