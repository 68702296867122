import React from 'react';
import { Patient } from '../types';
import { IoChevronBackOutline } from 'react-icons/io5';
import {
    FaRocket, FaHourglassHalf,
    FaLock, FaHeartbeat, FaMedkit,
    FaExclamationTriangle, FaDumbbell, FaExternalLinkAlt,
    FaSyncAlt
} from 'react-icons/fa';
import { GiPartyPopper } from 'react-icons/gi';
import styles from './PatientDetails.module.css';
import ProgressSteps from '../components/ProgressSteps';
import LearningAccommodations from '../components/LearningAccommodations';
import RehabProgress from '../components/RehabProgress';
import ActionButtons, { ActionButton } from '../components/ActionButtons';
import { useNavigate } from 'react-router-dom';

interface Step {
    title: string;
    status: 'completed' | 'here' | 'locked';
    statusText: string;
    description: string;
    category: string;
}

interface PatientDetailsProps {
    patient: Patient;
    onBack: () => void;
    teamMemberName?: string;
    teamMemberRole?: string;
}

const PatientDetails: React.FC<PatientDetailsProps> = ({ patient, onBack, teamMemberName, teamMemberRole }) => {
    const navigate = useNavigate();
    const firstName = patient.name.split(' ')[0];

    const canViewSection = (permissions?: string[]) => {
        return permissions?.some(permission =>
            permission === 'view' || permission === 'View-only'
        );
    };

    const canEditSection = (permissions?: string[]) => {
        return permissions?.some(permission =>
            permission === 'portal' || permission === 'Edit'
        );
    };

    const getTagStyle = (color: string) => {
        const lowerColor = color.toLowerCase();
        if (lowerColor.includes('red')) {
            return {
                backgroundColor: '#FFC2C3',
                color: '#511516'
            };
        }
        if (lowerColor.includes('yellow')) {
            return {
                backgroundColor: 'rgba(255, 170, 85, 0.15)',
                color: 'rgb(255, 100, 0)'
            };
        }
        if (lowerColor.includes('green')) {
            return {
                backgroundColor: '#C2FFC7',
                color: '#155116'
            };
        }
        return {
            backgroundColor: '#F2F2F2',
            color: '#666666'
        };
    };

    const getPlayIcon = (color: string) => {
        const lowerColor = color.toLowerCase();
        if (lowerColor.includes('red')) return <FaLock />;
        if (lowerColor.includes('yellow')) return <FaHourglassHalf />;
        if (lowerColor.includes('green')) return <FaRocket />;
        return <FaExclamationTriangle />;
    };

    const getRehabIcon = (color: string) => {
        const lowerColor = color.toLowerCase();
        if (lowerColor.includes('red')) return <FaMedkit />;
        if (lowerColor.includes('yellow')) return <FaHeartbeat />;
        if (lowerColor.includes('green')) return <FaDumbbell />;
        return <FaExclamationTriangle />;
    };

    const getLearnIcon = (color: string) => {
        const lowerColor = color.toLowerCase();
        if (lowerColor.includes('red')) return <FaHourglassHalf />;
        if (lowerColor.includes('yellow')) return <FaExclamationTriangle />;
        if (lowerColor.includes('green')) return <GiPartyPopper />;
        return <FaExclamationTriangle />;
    };

    const getStepsFromData = (
        titles: string[],
        statuses: string[],
        statusTexts: string[],
        descriptions: string[],
        categories: string[]
    ): Step[] => {
        const steps = categories.map((_, index) => ({
            title: titles[index],
            status: statuses[index] as 'completed' | 'here' | 'locked',
            statusText: statusTexts[index],
            description: descriptions[index],
            category: categories[index]
        }));
        console.log('Steps data: ', steps);
        return steps;
    };

    const handleSwitchToPatientView = () => {
        console.log('Switch to patient view - Debug info:', {
            patientUUID: patient.patient_uuid,
            teamMemberName: teamMemberName,
            patient: patient
        });

        // Get the current hostname and protocol
        const currentOrigin = window.location.origin;
        const url = `${currentOrigin}?uuid=${patient.patient_uuid || ''}&teamMemberName=${encodeURIComponent(teamMemberName || '')}`;
        
        console.log('Generated URL:', url);
        
        if (!patient.patient_uuid) {
            console.error('Patient UUID is missing!');
            return;
        }

        window.open(url, '_blank');
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <button onClick={onBack} className={styles.backButton}>
                    <IoChevronBackOutline />
                </button>
            </div>
            <div className={styles.content}>
                {/* Play Section - only show if play data exists */}
                {patient.play && canViewSection(patient.play.permissions_play) && (
                    <div className={styles.section}>
                        <h2 className={styles.sectionTitle}>
                            When will {firstName} be 100% back?
                        </h2>
                        <div
                            className={styles.sectionTag}
                            style={getTagStyle(patient.play.when_will_be_fully_back_tag_color)}
                        >
                            {getPlayIcon(patient.play.when_will_be_fully_back_tag_color)}
                            {patient.play.when_will_be_fully_back_tag}
                        </div>
                        <ProgressSteps
                            steps={getStepsFromData(
                                patient.play.play_titles,
                                patient.play.play_statuses,
                                patient.play.play_status_texts,
                                patient.play.play_descriptions,
                                patient.play.play_categories
                            )}
                        />
                        {canEditSection(patient.play.permissions_play) && (
                            <ActionButtons
                                buttons={[
                                    {
                                        icon: <FaExternalLinkAlt />,
                                        label: 'Modify return to play',
                                        href: 'https://app.headquarters.health/'
                                    }
                                ]}
                            />
                        )}
                    </div>
                )}

                {/* Rehab Section - only show if rehab data exists */}
                {patient.rehab && canViewSection(patient.rehab.permissions_rehab) && (
                    <div className={styles.section}>
                        <h2 className={styles.sectionTitle}>
                            Is {firstName} doing everything to get back?
                        </h2>
                        <div
                            className={styles.sectionTag}
                            style={getTagStyle(patient.rehab.recovery_effort_color)}
                        >
                            {getRehabIcon(patient.rehab.recovery_effort_color)}
                            {patient.rehab.recovery_effort}
                        </div>
                        <RehabProgress
                            yesterdayProgress={patient.rehab.yesterday_completion}
                            weekProgress={patient.rehab.past7days_completion}
                            totalProgress={patient.rehab.since_injury_completion}
                            patient={patient}
                        />
                        <ActionButtons
                            buttons={[
                                ...(canEditSection(patient.rehab.permissions_rehab) ? [{
                                    icon: <FaExternalLinkAlt />,
                                    label: 'Modify rehabilitation plan',
                                    href: 'https://app.headquarters.health/'
                                }] : []),
                                ...(patient.rehab.permissions_rehab?.includes('edit_patient_rehab') ? [{
                                    icon: <FaSyncAlt />,
                                    label: 'Switch to patient view',
                                    onClick: handleSwitchToPatientView
                                }] : [])
                            ]}
                        />
                    </div>
                )}

                {/* Learn Section - only show if learn data exists */}
                {patient.learn && canViewSection(patient.learn.permissions_learn) && (
                    <div className={styles.section}>
                        <h2 className={styles.sectionTitle}>
                            How can I support {firstName}'s learning?
                        </h2>
                        <div
                            className={styles.sectionTag}
                            style={getTagStyle(patient.learn.how_best_to_support_tag_color)}
                        >
                            {getLearnIcon(patient.learn.how_best_to_support_tag_color)}
                            {patient.learn.how_best_to_support_tag}
                        </div>
                        <ProgressSteps
                            steps={getStepsFromData(
                                Array(patient.learn.learn_titles.length).fill(''),
                                patient.learn.learn_statuses,
                                patient.learn.learn_status_texts,
                                patient.learn.learn_descriptions,
                                patient.learn.learn_titles
                            )}
                        />
                        {patient.learn.learning_accommodations?.length > 0 && (
                            <LearningAccommodations
                                accommodations={patient.learn.learning_accommodations}
                            />
                        )}
                        {canEditSection(patient.learn.permissions_learn) && (
                            <ActionButtons
                                buttons={[
                                    {
                                        icon: <FaExternalLinkAlt />,
                                        label: 'Modify return to learn',
                                        href: 'https://app.headquarters.health/'
                                    }
                                ]}
                            />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default PatientDetails;