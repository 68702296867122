import React, { createContext, useContext, ReactNode, useState, useEffect } from 'react';
import { getPatientData } from '../services/api'; // Import your API function

type UserRole = 'patient' | 'caretaker' | 'therapist' | 'admin';

interface AuthContextType {
  userRole: UserRole | null;
  isLoading: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

export function AuthProvider({ children }: AuthProviderProps) {
  const [userRole, setUserRole] = useState<UserRole | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchUserData() {
      try {
        const data = await getPatientData();
        console.log('Fetched user data:', data);
        setUserRole(data.role);
      } catch (error) {
        console.error('Failed to fetch user data:', error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchUserData();
  }, []);

  const value = {
    userRole,
    isLoading
  };

  console.log('AuthProvider state:', { userRole, isLoading });
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
} 