import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Overview from './pages/Overview';
import SplashScreen from './components/SplashScreen';
import SurveyPage from './pages/SurveyPage';
import VideoViewPage from './pages/VideoViewPage';
import { getPatientData, setCompletionStatus } from './services/api';
import { Exercise, Patient, isPatientOrCaretaker } from './types';
import './App.css';
import ResultsPage from './pages/ResultsPage';
import PatientsOverview from './pages/PatientsOverview';

// Create a separate component for the app content to use hooks
function AppContent() {
  const [isLoading, setIsLoading] = useState(true);
  const [rehabExercises, setRehabExercises] = useState<Exercise[]>([]);
  const [nonRehabExercises, setNonRehabExercises] = useState<Exercise[]>([]);
  const [fullName, setFullName] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [role, setRole] = useState<string>('patient');
  const [caretakerName, setCaretakerName] = useState<string>('');
  const [patients, setPatients] = useState<Patient[]>([]);
  const [showingPatientDetails, setShowingPatientDetails] = useState(false);
  const [teamMemberName, setTeamMemberName] = useState<string>('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Get URL parameters first
        const params = new URLSearchParams(window.location.search);
        const urlUuid = params.get('uuid');
        const urlTeamMemberName = params.get('teamMemberName');

        console.log('URL Parameters:', { urlUuid, urlTeamMemberName });

        const data = await getPatientData();
        if (!isPatientOrCaretaker(data.role)) {
          setPatients(data.data);
          setRole(data.role);
          // Use URL team member name if available, otherwise use from API
          setTeamMemberName(urlTeamMemberName || data.team_member_name || '');
          if (data.data.length === 1) {
            setShowingPatientDetails(true);
          }
        } else {
          setRehabExercises(data.data.rehabArray || []);
          setNonRehabExercises(data.data.nonRehabArray || []);
          setFullName(data.data.full_name || '');
          setRole(data.role);
          setCaretakerName(urlTeamMemberName || data.caretaker_name || '');
        }
        setIsLoading(false);
      } catch (err) {
        setError('Failed to fetch data');
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handlePatientSelect = (patientId: number) => {
    if (patientId === -1) {
      setShowingPatientDetails(false);
    } else {
      console.log('Selected patient:', patientId);
    }
  };

  if (isLoading) {
    return <SplashScreen />;
  }

  return (
    <>
      <div className="logo">headquarters</div>
      <Routes>
        <Route
          path="/"
          element={
            isPatientOrCaretaker(role) ? (
              <Overview
                rehabExercises={rehabExercises}
                nonRehabExercises={nonRehabExercises}
                fullName={fullName}
                error={error}
                role={role}
                patientName={fullName}
                teamMemberName={caretakerName}
              />
            ) : (
              <PatientsOverview 
                patients={patients} 
                onPatientSelect={handlePatientSelect}
                showingPatientDetails={showingPatientDetails}
                setShowingPatientDetails={setShowingPatientDetails}
                teamMemberName={teamMemberName}
              />
            )
          }
        />
        <Route
          path="/survey"
          element={
            <SurveyPage
              onAction={(action, content, patientId, added_rehab_id) => {
                setRehabExercises(prev =>
                  prev.map(exercise =>
                    exercise.added_rehab_id === added_rehab_id
                      ? { ...exercise, action, content }
                      : exercise
                  )
                );
                setCompletionStatus(action, content, patientId, added_rehab_id);
              }}
            />
          }
        />
        <Route 
          path="/video-view" 
          element={
            <VideoViewPage
              onAction={(action, content, patientId, added_rehab_id) => {
                setRehabExercises(prev =>
                  prev.map(exercise =>
                    exercise.added_rehab_id === added_rehab_id
                      ? { ...exercise, action, content }
                      : exercise
                  )
                );
                setCompletionStatus(action, content, patientId, added_rehab_id);
              }}
            />
          } 
        />
        <Route
          path="/results"
          element={<ResultsPage />}
        />
      </Routes>
    </>
  );
}

// Main App component that provides the Router context
function App() {
  return (
    <Router>
      <div className="App">
        <AppContent />
      </div>
    </Router>
  );
}

export default App;

// npm run build && firebase deploy