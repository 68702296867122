import React, { useState, useEffect } from 'react';
import { setCompletionStatus, resetExerciseStatus } from '../services/api';
import ExerciseCard from '../components/ExerciseCard';
import NonRehabExercises from '../components/NonRehabExercises';
import AudioPlayer from '../components/AudioPlayer';
import styles from './Overview.module.css';
import { Exercise, OverviewProps, CaretakerMode } from '../types';
import { useNavigate, useLocation } from 'react-router-dom';

const Overview: React.FC<OverviewProps> = ({
  rehabExercises,
  nonRehabExercises,
  fullName,
  error,
  role,
  patientName,
  teamMemberName,
  teamMemberRole,
  patientUuid
}) => {
  const [exercises, setExercises] = useState<Exercise[]>([]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Handle navigation state if coming from PatientDetails
    const state = location.state as any;
    if (state?.patientUuid) {
      // You might need to fetch patient-specific exercises here
      console.log('Viewing exercises for patient:', state.patientUuid);
    }
  }, [location]);

  useEffect(() => {
    const mappedExercises = rehabExercises.map(exercise => ({
      ...exercise,
      action: exercise.action || 'assigned',
      content: exercise.content || {}
    }));

    console.log('Mapped exercises with added_rehab_ids:', mappedExercises);
    setExercises(mappedExercises);
  }, [rehabExercises, nonRehabExercises, error]);

  console.log('Exercises state:', exercises);

  const handleAction = async (
    action: 'completed' | 'incomplete',
    content: { comments?: string } | {},
    patientId: string,
    added_rehab_id: string
  ) => {
    console.log('handleAction called with added_rehab_id:', added_rehab_id);

    // Optimistic UI update
    setExercises((prev) =>
      prev.map((exercise) => {
        return exercise.added_rehab_id === added_rehab_id
          ? {
            ...exercise,
            action,
            content: content || {},
          }
          : exercise;
      })
    );

    try {
      console.log("Setting completion status with:", { action, content, patientId, added_rehab_id });
      const patient_action_id = await setCompletionStatus(action, content, patientId, added_rehab_id);
      console.log("Completion status set with patient_action_id:", patient_action_id);

      // Update the exercise with the returned patient_action_id
      setExercises((prev) =>
        prev.map((exercise) =>
          exercise.added_rehab_id === added_rehab_id
            ? { ...exercise, action, content: content || {}, patient_action_id }
            : exercise
        )
      );

      return patient_action_id;
    } catch (err) {
      // Revert UI update on error
      setExercises((prev) =>
        prev.map((exercise) =>
          exercise.added_rehab_id === added_rehab_id
            ? { ...exercise, action: 'assigned', content: {} }
            : exercise
        )
      );
      console.error('Error updating exercise status:', err);
    }
  };

  const handleReset = async (added_rehab_id: string, patient_action_id: number) => {
    // Optimistic UI update
    setExercises((prev) =>
      prev.map((exercise) =>
        exercise.added_rehab_id === added_rehab_id
          ? {
            ...exercise,
            action: 'assigned',
            content: {
              ...(exercise.content?.video ? { video: exercise.content.video } : {}),
              ...(exercise.content?.survey_question_list ? { survey_question_list: exercise.content.survey_question_list } : {})
            },
          }
          : exercise
      )
    );

    try {
      await resetExerciseStatus(patient_action_id);
    } catch (err) {
      // Revert UI update on error
      setExercises((prev) =>
        prev.map((exercise) =>
          exercise.added_rehab_id === added_rehab_id
            ? { ...exercise }
            : exercise
        )
      );
      console.error('Error resetting exercise:', err);
    }
  };

  const handleViewResults = (added_rehab_id: string) => {
    const urlParams = new URLSearchParams(window.location.search);
    const uuid = urlParams.get('uuid') || 'null';

    console.log('Navigating to results with:', { uuid, added_rehab_id });
    navigate('/results', {
      state: {
        uuid,
        added_rehab_id
      },
      replace: false
    });
  };

  if (error) return <div>{error}</div>;

  return (
    <div>
      <NonRehabExercises
        exercises={nonRehabExercises}
        fullName={fullName}
        role={role}
        patientName={patientName}
        teamMemberName={teamMemberName}
        teamMemberRole={teamMemberRole}
      />
      <h2 className={styles.sectionTitle}>Today's drills 💪</h2>
      {exercises.length > 0 ? (
        exercises.map((exercise) => (
          <ExerciseCard
            key={exercise.added_rehab_id}
            exercise={exercise}
            onAction={handleAction}
            onReset={handleReset}
            onViewResults={handleViewResults}
          />
        ))
      ) : (
        <div className={styles.emptyStateContainer}>
          <p className={styles.emptyStateText}>
            All caught up! <br></br>Time to celebrate your progress 🎉
          </p>
        </div>
      )}
    </div>
  );
};

export default Overview;
