import React, { useState, useRef, useEffect } from 'react';
import { Exercise } from '../types';
import styles from './ExerciseCard.module.css';
import { useNavigate } from 'react-router-dom';
import { resetExerciseStatus } from '../services/api';
import { FaRegQuestionCircle } from 'react-icons/fa';
import TagInfoWindow from './TagInfoWindow';

interface ExerciseCardProps {
  exercise: Exercise & {
    content?: {
      comments?: string;
      survey_question_list?: any[];
      video?: string;
    };
    patient_action_id?: number;
  };
  onAction: (
    action: 'completed' | 'incomplete',
    content: { comments?: string } | {},
    patientId: string,
    added_rehab_id: string
  ) => Promise<number>;
  onReset: (added_rehab_id: string, patient_action_id: number) => void;
  onViewResults: (added_rehab_id: string) => void;
}

const ExerciseCard: React.FC<ExerciseCardProps> = ({ exercise, onAction, onReset, onViewResults }) => {
  const [comments, setComments] = useState<string>(
    typeof exercise.content === 'object' && exercise.content !== null
      ? (exercise.content as { comments?: string }).comments || ''
      : ''
  );
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [showWhyPopup, setShowWhyPopup] = useState(false);

  const isActionDisabled = exercise.action !== 'assigned';
  const isSurvey = exercise.content && ('survey_question_list' in exercise.content || 'survey_answers' in exercise.content);
  const isVideo = exercise.content && 'video' in exercise.content;
  const isPCSSSurvey = exercise.title === 'Symptom Survey (PCSS)';
  const showSeeResults = isPCSSSurvey && exercise.action === 'completed';

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [comments]);

  const handleComplete = async () => {
    if (isVideo) {
      navigate('/video-view', {
        state: {
          added_rehab_id: exercise.added_rehab_id,
          patientId: exercise.patientId,
          videoUrl: exercise.content?.video,
          description: exercise.description,
          content: exercise.content
        }
      });
    } else if (isSurvey) {
      navigate('/survey', {
        state: {
          surveyQuestions: exercise.content?.survey_question_list,
          added_rehab_id: exercise.added_rehab_id,
          patientId: exercise.patientId
        }
      });
    } else {
      try {
        const patient_action_id = await onAction('completed', comments ? { comments } : {}, exercise.patientId, exercise.added_rehab_id);
        exercise.patient_action_id = patient_action_id;
      } catch (error) {
        console.error('Error completing exercise:', error);
      }
    }
  };

  const handleIncomplete = () => {
    onAction('incomplete', comments ? { comments } : {}, exercise.patientId, exercise.added_rehab_id);
  };

  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComments(e.target.value);
  };

  const getCompleteButtonClass = () => {
    if (exercise.action === 'completed') {
      return `${styles.button} ${styles.complete} ${styles.completedGreen}`;
    } else if (exercise.action === 'incomplete') {
      return `${styles.button} ${styles.complete} ${styles.incompleteYellow}`;
    }
    return `${styles.button} ${styles.complete}`;
  };

  const handleReset = async () => {
    try {
      if (!exercise.patient_action_id) {
        console.error('Missing patient_action_id');
        return;
      }
      onReset(exercise.added_rehab_id, exercise.patient_action_id);
    } finally {
      setMenuOpen(false);
    }
  };

  const handleSeeResults = () => {
    if (!exercise.patientId || !exercise.added_rehab_id) {
      console.error('Missing required exercise data:', exercise);
      return;
    }
    onViewResults(exercise.added_rehab_id);
  };

  return (
    <div className={styles['exercise-card']}>
      <div className={styles['card-header']}>
        <div className={styles['title-container']}>
          <h2>{exercise.title || 'No Title Provided'}</h2>
          {exercise.why_simple && (
            <FaRegQuestionCircle
              className={styles['why-icon']}
              onClick={(e) => {
                e.stopPropagation();
                setShowWhyPopup(true);
              }}
            />
          )}
        </div>
        <div
          className={styles['menu-button']}
          onClick={() => setMenuOpen(!menuOpen)}
        >
          {exercise.action !== 'assigned' && (
            <>
              ⋮
              {menuOpen && (
                <div className={styles.dropdown}>
                  <div
                    className={styles['dropdown-item']}
                    onClick={handleReset}
                  >
                    Reset
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {!isVideo && !isSurvey && (
        <pre className={styles.description}>
          {exercise.description || 'No Description Provided'}
        </pre>
      )}
      {!isSurvey && !isVideo && (
        <textarea
          ref={textareaRef}
          className={styles.comments}
          value={comments}
          onChange={handleCommentChange}
          placeholder="Add any comments..."
          disabled={isActionDisabled}
          rows={1}
        />
      )}
      <div className={styles['button-container']}>
        <button
          className={getCompleteButtonClass()}
          onClick={handleComplete}
          disabled={isActionDisabled}
        >
          {exercise.action === 'completed' ? 'Completed' :
            exercise.action === 'incomplete' ? 'Incomplete' :
              (isSurvey || isVideo) ? 'Start' : 'Mark as complete'}
        </button>
        {/* {showSeeResults && (
          <button
            className={`${styles.button} ${styles.seeResults}`}
            onClick={handleSeeResults}
          >
            See Results
          </button>
        )} */}
        {!isSurvey && !isVideo && exercise.action !== 'completed' && exercise.action !== 'incomplete' && (
          <button
            className={`${styles.button} ${styles.incomplete}`}
            onClick={handleIncomplete}
            disabled={isActionDisabled}
          >
            Can't complete?
          </button>
        )}
      </div>
      {showWhyPopup && exercise.why_simple && (
        <TagInfoWindow
          title={exercise.title}
          description={exercise.why_simple}
          customTitle="Why is this important?"
          onClose={() => setShowWhyPopup(false)}
        />
      )}
    </div>
  );
};

export default ExerciseCard;
