import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AppComponent from './App';
import { AuthProvider, useAuth } from './context/AuthContext';
import SplashScreen from './components/SplashScreen';

function ThemedApp() {
  const { userRole, isLoading } = useAuth();
  const isDarkMode = isLoading || userRole === 'patient' || userRole === 'caretaker';
  
  useEffect(() => {
    console.log('Current role:', userRole);
    console.log('Should be dark mode?', isDarkMode);
    
    document.documentElement.classList.remove('light-mode', 'dark-mode');
    const themeClass = isDarkMode ? 'dark-mode' : 'light-mode';
    document.documentElement.classList.add(themeClass);
    console.log('Applied theme class:', themeClass);
  }, [isDarkMode, isLoading, userRole]);

  if (isLoading) {
    return <SplashScreen />;
  }

  return <AppComponent />;
}

function App() {
  return (
    <AuthProvider>
      <ThemedApp />
    </AuthProvider>
  );
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
